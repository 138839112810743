import clickTrack from './js/clickTrack';
import {
  handleNewsletterForm,
  handleContactForm,
  handleZoningForm,
  handleCalendlyForm,
} from './js/handleForm';
import popupOpen from './js/popupOpen';

window.Webflow ||= [];
window.Webflow.push(() => {
  popupOpen();
  clickTrack('configure-btn');
  handleNewsletterForm();
  handleContactForm();
  handleZoningForm();
  handleCalendlyForm();
});
