const clickTrack = function (className) {
  document.addEventListener('click', (e) => {
    const button = e.target.closest(`.${className}`);

    if (!button) return;

    const label = button.getAttribute('data-customerIO-label');
    analytics.track('button_click', {
      label: label,
    });
  });
};

export default clickTrack;
