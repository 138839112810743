const saveUserProfile = function (form) {
  const formData = new FormData(form);

  // Getting user profile from form data
  const getUserProfile = function (formData) {
    let name = formData.get('name') || formData.get('Name');
    const firstName = formData.get('first-name') || formData.get('First-Name');
    const lastName = formData.get('last-name') || formData.get('Last-Name');
    const email = formData.get('email') || formData.get('Email');
    const phone = formData.get('phone') || formData.get('Phone');

    // Combine first and last name if they exist
    if (!name && (firstName || lastName)) {
      name =
        firstName && lastName
          ? `${firstName} ${lastName}`
          : firstName || lastName;
    }

    const userProfile = {};
    if (name) userProfile.name = name;
    if (email) userProfile.email = email;
    if (phone) userProfile.phone = phone;

    return userProfile;
  };

  // Set user profile in local storage (convert to string)
  const userProfile = getUserProfile(formData);
  localStorage.setItem('userProfile', JSON.stringify(userProfile));
};

export default saveUserProfile;
