// Prefill the form from an object
function prefillForm(form, objectData) {
  if (!objectData) return;

  Object.keys(objectData).forEach((key) => {
    const value = objectData[key];
    if (value) {
      // Prefill inputs with matching name attributes (case-insensitive)
      form
        .querySelectorAll(
          `input[name="${key}"], input[name="${
            key.charAt(0).toUpperCase() + key.slice(1)
          }"]`
        )
        .forEach((input) => {
          input.value = value;
        });
    }
  });
}

export default prefillForm;
