const checkFormSuccess = function (form) {
  return new Promise((resolve, reject) => {
    const parentElement = form.parentElement;
    const checkSuccess = setInterval(() => {
      let successMessage = parentElement.querySelector('.w-form-done');

      if (successMessage && successMessage.style.display !== 'none') {
        clearInterval(checkSuccess); // Stop checking
        resolve(); // Resolve the promise
      }
    }, 100);
  });
};

export default checkFormSuccess;
